// @ts-strict-ignore
import React, { Fragment, useState } from "react"

import { addDays, startOfTomorrow } from "date-fns"
import { Form, Formik } from "formik"

import { Grid, TextLink } from "@kiwicom/orbit-components"
import Alert from "@kiwicom/orbit-components/lib/Alert"
import Button from "@kiwicom/orbit-components/lib/Button"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import { parseErrorMessage } from "api/errors"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import { FormikCheckbox } from "components/generic/ember-modal/form-modal"
import {
  EmberDateInput,
  EmberFormSelect,
} from "components/generic/formik-inputs"

import Yup from "logic/validation/yup-extended"

import { Route } from "types/location"
import { RouteEligibility } from "types/person"

import { zonedEndOfDay, zonedStartOfDay } from "utils/date-fns-utils"
import { formatAsMinimizedDate, getDefaultTimezone } from "utils/date-utils"
import { asyncFetchFromAPIBase } from "utils/fetch-utils"
import { useGlobalFetcher } from "utils/state-utils"

interface EligibilityTableProps {
  routesEligibilities: RouteEligibility[]
  selectEligibility: (route: RouteEligibility) => void
  type: "Active" | "Historic"
}

const EligibilityTable = ({
  routesEligibilities,
  selectEligibility,
  type,
}: EligibilityTableProps) => {
  if (routesEligibilities.length == 0) {
    return (
      <Text>
        {type == "Active"
          ? "This driver is not currently eligibile to drive any routes."
          : "This driver has no old route eligibility records."}
      </Text>
    )
  }
  return (
    <Grid columns="repeat(4, max-content)" columnGap="18px" rowGap="12px">
      <Text weight="bold">Valid From</Text>
      <Text weight="bold">Valid Until</Text>
      <Text weight="bold">Route Number</Text>
      <Text weight="bold"> </Text>
      {routesEligibilities.map((route, index) => (
        <Fragment key={index}>
          <Stack align="center" dataTest="route-eligibility-row">
            <Text>
              {formatAsMinimizedDate(route.validFrom, getDefaultTimezone())}
            </Text>
          </Stack>
          <Stack align="center">
            <Text>
              {route.validUntil
                ? formatAsMinimizedDate(route.validUntil, getDefaultTimezone())
                : "No end date"}
            </Text>
          </Stack>
          <Stack align="center">
            <Text
              dataTest={`route-eligibility-route-number--${route.routeNumber}`}
            >
              {route.routeNumber}
            </Text>
          </Stack>
          <TextLink
            type="secondary"
            onClick={() => selectEligibility(route)}
            dataTest="edit-route-eligibility"
          >
            Edit
          </TextLink>
        </Fragment>
      ))}
    </Grid>
  )
}

function getRouteNumberOptions(routes: Route[]) {
  let routeNumbers = routes
    ?.filter((route) => route.number !== "SPECIAL" && !route.inactiveFrom)
    .map((route) => route.number)
  routeNumbers = [...new Set(routeNumbers)]
  return routeNumbers.map((routeNumber) => ({
    id: routeNumber,
    title: routeNumber,
  }))
}

interface RouteEligibilityModalProps {
  driverUid: string
  activeRoutes: RouteEligibility[]
  historicRoutes: RouteEligibility[]
  refreshOnEdit: () => void
  handleClose: () => void
}

const RouteEligibilityModal = ({
  driverUid,
  activeRoutes,
  historicRoutes,
  handleClose,
  refreshOnEdit,
}: RouteEligibilityModalProps) => {
  const [editEligibility, setEditEligibility] = useState(false)
  const [addEligibility, setAddEligibility] = useState(false)
  const [deleteEligibility, setDeleteEligibility] = useState(false)
  const [showHistoricEligibilities, setShowHistoricEligibilities] =
    useState(false)
  const [selectedEligibility, setSelectedEligibility] =
    useState<RouteEligibility>(null)
  const { data: routes, fetching } = useGlobalFetcher("routes")
  const routeNumberOption = getRouteNumberOptions(routes)
  const selectEligibility = (route: RouteEligibility) => {
    setSelectedEligibility(route)
    setEditEligibility(true)
  }

  // console.log("activeRoutes", activeRoutes)

  return (
    <EmberModal size="small" onClose={handleClose}>
      <EmberModalHeader
        title="Route Eligibility"
        dataTest="route-eligibility-modal-header"
      />
      <EmberModalSection>
        <Stack spacing="large">
          {(addEligibility || editEligibility) && (
            <Formik
              initialValues={{
                routeNumber: editEligibility
                  ? {
                      id: selectedEligibility.routeNumber,
                      title: selectedEligibility.routeNumber,
                    }
                  : routeNumberOption[0],
                validFrom: editEligibility
                  ? new Date(selectedEligibility.validFrom)
                  : startOfTomorrow(),
                validUntil:
                  editEligibility && selectedEligibility.validUntil
                    ? new Date(selectedEligibility.validUntil)
                    : addDays(startOfTomorrow(), 1),
                openEnded: editEligibility
                  ? !selectedEligibility.validUntil
                  : true,
              }}
              validationSchema={Yup.object({
                validFrom: Yup.date()
                  .required("You must enter a valid from time")
                  .typeError("You must enter a valid from time"),
                validUntil: Yup.date().endDateGreaterThanStart(
                  "validFrom",
                  "openEnded"
                ),
              })}
              enableReinitialize
              onSubmit={async (values, { setSubmitting, setStatus }) => {
                setSubmitting(true)
                setStatus({
                  success: false,
                  error: null,
                })
                const { routeNumber, validFrom, validUntil, openEnded } = values

                const body = {
                  route_number: routeNumber.id,
                  validFrom: zonedStartOfDay(
                    validFrom,
                    getDefaultTimezone()
                  ).toISOString(),
                  validUntil: openEnded
                    ? null
                    : zonedEndOfDay(
                        validUntil,
                        getDefaultTimezone()
                      ).toISOString(),
                }

                let response: any = null
                if (addEligibility) {
                  response = await asyncFetchFromAPIBase({
                    path: `/v1/drivers/${driverUid}/routes/`,
                    method: "POST",
                    authRequired: true,
                    body: body,
                  })
                }
                if (editEligibility && !deleteEligibility) {
                  response = await asyncFetchFromAPIBase({
                    path: `/v1/drivers/${driverUid}/routes/${selectedEligibility.id}/`,
                    method: "PATCH",
                    authRequired: true,
                    body: body,
                  })
                }

                if (deleteEligibility) {
                  response = await asyncFetchFromAPIBase({
                    path: `/v1/drivers/${driverUid}/routes/${selectedEligibility.id}/`,
                    method: "DELETE",
                    authRequired: true,
                  })
                }

                if (response.error) {
                  setStatus({
                    success: false,
                    error: `
                    Error ${
                      addEligibility ? "adding" : "editing"
                    } route eligibility. The error message was "${parseErrorMessage(
                      response
                    )}" `,
                  })
                } else {
                  refreshOnEdit()
                  setStatus({
                    success: true,
                  })
                }
                setSubmitting(false)
              }}
            >
              {(props) => (
                <>
                  <Form>
                    <Stack spacing="large">
                      {deleteEligibility && (
                        <Text>
                          Are you sure you wish to remove the driver's
                          eligibility to drive the{" "}
                          {selectedEligibility.routeNumber} route?
                        </Text>
                      )}
                      {!deleteEligibility && (
                        <>
                          <EmberFormSelect
                            options={routeNumberOption}
                            name="routeNumber"
                            label="Route Number"
                            loading={fetching}
                            dataTest="route-number-select"
                          />
                          <EmberDateInput
                            name="validFrom"
                            label="Valid From"
                            closeOnSelect
                          />
                          <FormikCheckbox
                            name="openEnded"
                            label="No end date"
                          />
                          {!props.values.openEnded && (
                            <EmberDateInput
                              name="validUntil"
                              label={"Valid Until"}
                              minDate={addDays(props.values.validFrom, 1)}
                              closeOnSelect
                            />
                          )}
                        </>
                      )}
                      <Stack spacing="XSmall">
                        <Button
                          disabled={props.isSubmitting}
                          loading={props.isSubmitting}
                          fullWidth={true}
                          submit={true}
                          type={deleteEligibility ? "critical" : "primary"}
                          dataTest="edit-route-eligibility-form-submit"
                        >
                          {deleteEligibility ? "Remove" : "Save"}
                        </Button>
                        {editEligibility && !deleteEligibility && (
                          <Button
                            type="criticalSubtle"
                            onClick={() => setDeleteEligibility(true)}
                            fullWidth
                            dataTest="delete-route-eligibility"
                          >
                            Remove Eligibility
                          </Button>
                        )}
                      </Stack>
                      {props.status?.error && (
                        <Alert type="critical">{props.status.error}</Alert>
                      )}
                    </Stack>
                  </Form>
                </>
              )}
            </Formik>
          )}
          {!editEligibility && !addEligibility && (
            <>
              <EligibilityTable
                routesEligibilities={
                  showHistoricEligibilities ? historicRoutes : activeRoutes
                }
                selectEligibility={selectEligibility}
                type={showHistoricEligibilities ? "Historic" : "Active"}
              />
              <Stack spacing="XSmall">
                <Button
                  fullWidth={true}
                  onClick={() => setAddEligibility(true)}
                  dataTest="add-route-eligibility"
                >
                  Add Eligibility
                </Button>
                {historicRoutes.length > 0 && (
                  <Button
                    type="primarySubtle"
                    onClick={() =>
                      setShowHistoricEligibilities(!showHistoricEligibilities)
                    }
                    fullWidth
                    dataTest="view-historic-route-eligibility"
                  >
                    {showHistoricEligibilities
                      ? "View Active Eligibility"
                      : "View Historic Eligibility"}
                  </Button>
                )}
              </Stack>
            </>
          )}
        </Stack>
      </EmberModalSection>
    </EmberModal>
  )
}

export default RouteEligibilityModal
